<template>
  <div>
    <el-button @click="handleAdd" type="primary" size="small">新增</el-button>

    <el-table :data="tableData" v-loading="tableLoading" border stripe
      :header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="500px" class="margin-t-10"
      style="width: 100%;">
      <el-table-column label="#" type="index" width="60"></el-table-column>
      <el-table-column label="疫苗种类名称" prop="type_name"></el-table-column>
      <el-table-column label="操作">
        <template v-slot="{ row }">
          <el-link @click="handleEdit(row)" type="primary" class="margin-r-10">编辑</el-link>
          <el-popconfirm title="确定删除吗？" @confirm="handleDel(row.id)">
            <el-link slot="reference" type="danger">删除</el-link>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="prev, pager, next, total, jumper" :total="total"
        :current-page.sync="queryForm.page" @current-change="getVaccineCategories">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <!-- 新增、编辑的弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" :before-close="closeDialog"
      width="500px">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="疫苗种类名称" prop="type_name">
          <el-input v-model="form.type_name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button :loading="submitting" @click="handleSubmit" type="primary">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getVaccineCategoriesAPI,
    saveVaccineCategoryAPI,
    delVaccineCategoryAPI,
  } from '@/api/vaccine.js'

  export default {
    name: 'VaccineCategory',
    data() {
      return {
        queryForm: {
          page: 1,
          page_size: 10
        },

        dialogTitle: '',
        showDialog: false,
        submitting: false,
        form: {
          type_name: '',
        },
        rules: {
          type_name: [{
            required: true,
            message: '疫苗种类名称不能为空',
            trigger: 'blur'
          }],
        },

        tableData: [],
        total: 0,
        tableLoading: false,
        curRowId: '',
      }
    },
    created() {
      this.getVaccineCategories()
    },
    methods: {
      handleAdd() {
        this.showDialog = true
        this.dialogTitle = '新增'
      },
      closeDialog() {
        this.$refs.form.resetFields()
        this.showDialog = false
      },
      handleEdit(row) {
        this.showDialog = true
        this.dialogTitle = '编辑'
        this.curRowId = row.id
        const copyRow = JSON.parse(JSON.stringify(row))
        // 回显
        for (let key in this.form) {
          this.form[key] = copyRow[key]
        }
      },
      handleDel(id) {
        delVaccineCategoryAPI({
          id,
        }).then(res => {
          this.$message.success(res.msg)
          this.getVaccineCategories()
        })
      },
      handleSubmit() {
        this.$refs.form.validate(isValid => {
          if (!isValid) return

          this.submitting = true
          const copyForm = Object.assign({}, this.form)

          if (this.dialogTitle == '编辑') copyForm.id = this.curRowId

          saveVaccineCategoryAPI(copyForm).then(res => {
            this.$message.success(res.msg)
            this.closeDialog()
            this.getVaccineCategories()
          }).finally(() => this.submitting = false)
        })
      },

      getVaccineCategories() {
        this.tableLoading = true

        getVaccineCategoriesAPI(this.queryForm).then(res => {
          this.tableData = res.data
          this.total = res.total
          this.tableLoading = false
        })
      },
    }
  }
</script>

<style>
</style>